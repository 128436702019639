/* Parent container for the layout */
.app-container {
  display: flex;
  height: 100%;
}

/* Content container */
.content-container {
  flex-grow: 1;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%; /* Add this to ensure the content container fills its parent */
}
