.box-style {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 30px;
  padding: 10px;
  text-align: center;
  width: 85%;
  max-width: 870px;
  min-width: 600px;
  border-radius: 20px;
}

@media screen and (max-width: 600px) {
  .box-style {
    width: 100%;
    margin: 10px auto;
    min-width: 300px;
  }
}
